import _ from "lodash";

export function safeEndsWith(
    mainString: string | null | undefined,
    suffix: string | null | undefined
): boolean {
    // Ensure both values are valid strings before using _.endsWith
    if (!_.isString(mainString) || !_.isString(suffix)) {
        return false;
    }
    return _.endsWith(mainString, suffix);
}