import React, {useCallback, useContext, useEffect, useState} from "react";
import {DeleteOutlined, DownloadOutlined, UploadOutlined} from '@ant-design/icons';
import {IGenerateBillProps} from "./IGenerateBillProps";
import "./GenerateBill.css";
import {
  Button,
  Col,
  Input,
  InputNumber,
  message,
  Modal,
  notification,
  Popconfirm,
  Radio,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Upload,
} from "antd";
import moment from "moment";
import {city, country} from "../../Models/city.model";
import {BillProducts, product, storeProduct,} from "../../Models/Products";
import {cities_Company_Transports, company_transport,} from "../../Models/company_Transport";
import _ from 'lodash';

import {storeModel} from "../../Models/store";
import {StoreClient} from "../../Models/StoreClient";
import {axiosInstance} from "../../commons/services/axiosConfig";
import {PageContext} from "../../Hooks/ContextProvider";
import {Bill, BillData, ChanelPreBill, PreBill} from "../../Models/Bill";
import ModalDetalleGuia from "../ModalDetalleGuia/ModalDetalleGuia";
import TablePreBill from "../TablePreBill/TablePreBill";
import {Popup} from "devextreme-react";
import {formatAddress, validAddress} from "../../commons/utils/addressUtils";
import {
  calculateCollectAmount,
  emptyClient,
  formatBillProducts,
  prepareClient,
  prepareSender,
  priceSelectorByCurrency,
  productSelectorByCurrency
} from "../../commons/utils/billUtils";
import {UserData} from "../../Models/User.Model";
import {COURIERS} from "../../couriers/utils";
import {CITIES} from "../../geo/utils";

const { Option } = Select;
const RadioGroup = Radio.Group;

const GenerateBill = (props: IGenerateBillProps) => {
  const {userData, setSelectedComponent} = useContext(PageContext);

  const [bootstrapping, setBootstrapping] = useState<boolean>(true);
  const [store, setStore] = useState<storeModel | undefined>(undefined);
  const [countries, setCountries] = useState<country[]>([]);
  const [cities, setCities] = useState<Array<city>>([]);
  const [couriers, setCouriers] = useState<company_transport[]>([]);
  const [products, setProducts] = useState<product[]>([]);
  const [preBills, setPreBills] = useState<PreBill[]>([]);

  const [client, setClient] = useState<StoreClient>(emptyClient());
  const [cellphone, setCellphone] = useState<string>("");
  const [fullname, setFullname] = useState<string>("");
  const [idNumber, setIdNumber] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [selectedCountryId, setSelectedCountryId] = useState<number>(parseInt(localStorage.country));
  const [selectedCityId, setSelectedCityId] = useState<number>();
  const [selectedCourierId, setSelectedCourierId] = useState<number>();
  const [deliveryComments, setDeliveryComments] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [province, setProvince] = useState<string>("");

  const [productsListFiltered, setProductsListFiltered] = useState<Array<product>>([]);
  const [billProducts, setBillProducts] = useState<Array<BillProducts>>([]);
  const [isStorePickup, setIsStorePickup] = useState<boolean>(false);
  const [isCashOnDelivery, setIsCashOnDelivery] = useState<boolean>(true);
  const [collectAmount, setCollectAmount] = useState<number>(0);
  const [isCasOnDeliveryDisabled, setIsCasOnDeliveryDisabled] = useState<boolean>(false);
  const [savingBill, setSavingBill] = useState(false);

  const [currentStep, setCurrentStep] =
      useState<"Datos del Cliente" | "Localización y envío" | "Productos" >("Datos del Cliente");
  const [clientBills, setClientBills] = useState([]);
  const [showShippingLabel, setShowShippingLabel] = useState<boolean>(false);

  const [billData, setBillData] = useState<BillData>();
  const [currentPreBills, setCurrentPreBills] = useState<PreBill[]>([]);
  const [popupWithScrollViewVisible, setPopupWithScrollViewVisible] = useState(false);

  const hide = useCallback(() => {
    setPopupWithScrollViewVisible(false);
  }, [setPopupWithScrollViewVisible]);

  const formatLocationIndications = (): string => {
    return selectedCityId === CITIES.OTHER_CITY.id
        ? `, ${city} - ${province}`
        : '';
  }
  const prepareBill = (): BillData => {
    const preparedClient: StoreClient = prepareClient(userData as UserData, client, cellphone, fullname, idNumber, email, selectedCityId as number);

    return {
      id: 0,
      anulation_comment_id: 0,
      created_at: moment(new Date()).format("YYYY-MM-DD"),
      updated_at: moment(new Date()).format("YYYY-MM-DD"),
      delivery_News: [],
      storeClient: preparedClient,
      envia: prepareSender(
          store,
          {billProducts, products},
          isCashOnDelivery,
          preparedClient,
          deliveryComments
      ),
      guia: '',
      delivery_value: 0,
      status: "Pendiente",
      payment_home: isCashOnDelivery,
      collect_value: isCashOnDelivery
          ? collectAmount
          : 0,
      authorized: true,
      email: userData?.email || '',
      store_ClientId: 0,
      storeId: parseInt(localStorage.storeID),
      origin_city: 6834,
      created_by: parseInt(localStorage.userID),
      bill_Products: billProducts,
      company_TransportId: selectedCourierId ?? 2,
      observaciones_Envio: `${formatBillProducts({billProducts, products})} / ${deliveryComments}`,
      deliveryInWarehouse: isStorePickup,
      deliveryComments: deliveryComments,
      adress_indications: formatLocationIndications(),
      cityId: selectedCityId as number,
      address: formatAddress(preparedClient),
    }
  }

  const saveClient = async (): Promise<StoreClient> => {
    const preparedClient = prepareClient(userData as UserData, client, cellphone, fullname, idNumber, email, selectedCityId as number);

    const doesClientExist = !_.isNil(preparedClient.id);
    let savedClient: StoreClient;

    if (doesClientExist) {
      await axiosInstance.put(`/StoreClient/${preparedClient?.id}`, preparedClient);
      savedClient = preparedClient
    } else {
      const response = await axiosInstance.post<StoreClient>(`/StoreClient`, preparedClient);
      savedClient = response.data;
    }

    setClient(savedClient)

    return savedClient;
  };

  const fetchClientBills = async (storeClientId: number) => {
    let resp = await axiosInstance.get(`/Bills/GetLastBuysByClient?storeClientId=${storeClientId}`);
    let lastBill = resp.data.map((bill: any) => {
      let prod: product[] = products.filter(
          (prod) => prod.id === bill.productId
      );
      return {
        guia: bill.guia,
        status: bill.status,
        quantity: bill.quantity,
        date: moment(bill.created_at).format("DD/MM/YYYY"),
        product: prod.length > 0 ? prod[0].name : "-",
      };
    });
    setClientBills(lastBill);
  };

  const fetchCountries = async () => {
    try {
      const resp = await axiosInstance.get<country[]>("/Countries");
      setCountries(resp.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProducts = async () => {

    const activeSelector = productSelectorByCurrency(localStorage.currency);
    const activeProducts  = await axiosInstance.get<product[]>("/Products")
        .then(response => response.data.filter((p: product) => activeSelector(p)));

    const storeProducts = await axiosInstance.get<storeProduct[]>(`/StoreProducts?id=${userData?.storeID}&Currency=${localStorage.currency}`)
        .then(response => {
          const products = response.data
              .map((sp: storeProduct) => activeProducts.find((p: product) => p.id === sp.productId))
              .filter((p) => p !== undefined);
          return _.uniqBy(products, 'id');
        }) as product[];

    setProducts(storeProducts);
    setProductsListFiltered(storeProducts);

    if (storeProducts.length == 0) {
      message.warning(
          "Tú tienda aún no tiene productos disponibles para vender"
      );
    }
  };

  const fetchCouriers = async () => {
    let resp = await axiosInstance.get<company_transport[]>("/CompanyTransports");
    setCouriers(resp.data);
  };

  const fetchStore = async () => {
    let resp = await axiosInstance.get(`/Stores/${userData?.storeID}`);
    if (resp.status === 200) {
      setStore(resp.data);
    }
  };

  const fetchPresales = async () => {
    axiosInstance.get(`/PreBills`).then((response) => {
      setPreBills(response.data);
    })
        .catch((error) => {
          notification.error(error.errorMessage)
          console.error('Error al intentar obtener las preventas', error);
        });
  }

  const handleCellphoneChange = async (phone: string) => {
    if (_.isEmpty(phone))
      return;

    setBootstrapping(true);

    const params = {cellphone: phone};
    const response = await axiosInstance.get<StoreClient>("/StoreClient", {params});
    const client = response.data;
    if (_.isEmpty(client)){
      setBootstrapping(false);
      return;
    }


    setClient(client);

    setFullname(client.name);
    setIdNumber(client.identification);
    setEmail(client.email);

    await fetchClientBills(client.id as number);

    if(client.warningComments){
      openWarningNotification(client.warningComments)
    }

    handleCityChange(client.cityId);

    setBootstrapping(false);
  };

  const handleCountryChange = async (countryId: number) => {
    try {
      const citiesResponse = await axiosInstance.get<city[]>("/Cities/CitiesByCountry", {
        params: { countryId: countryId },
      });

      const cities = citiesResponse.data
          .map(c => {
            return {...c, name: `${c.name} (${c.provinceName})`}
          })
          .filter(c => c.isActived == true);

      setSelectedCountryId(countryId);
      setCities(cities);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCityChange = (cityId: number | undefined) => {
    if(!cityId){
      setSelectedCityId(undefined);
      return;
    }

    const selectedCity: city | undefined = cities.find((c) => c.id == cityId);
    if(selectedCity === undefined || !selectedCity.isActived){
      setSelectedCityId(undefined);
      return;
    }

    if (selectedCity.message) {
      Modal.warning({title: "¡Atención!", content: selectedCity.message})
    }

    const courierCity = couriers
        .map((c) => c.cities_Company_Transports)
        .flat()
        .find((c) => c.cityId === selectedCity.id);
    if (courierCity?.rx) {
      Modal.warning({
        title: "Esta ciudad es una reexpedición",
        content: "Su entrega puede tardar entre 8 y 15 dias.",
      });
    }

    setSelectedCityId(selectedCity.id)
  }

  const handleCourierChange = (courierId: number) => {
    let courier = couriers.find(c => c.id == courierId);
    if(courier === undefined){
      setSelectedCourierId(undefined);
      setIsCashOnDelivery(true);
      setIsCasOnDeliveryDisabled(false);

      return;
    }

    let cityConfig = courier
        .cities_Company_Transports
        .find(c => c.cityId === selectedCityId) as cities_Company_Transports;

    setSelectedCourierId(courierId);
    setIsCashOnDelivery(cityConfig.payment_home);
    setIsCasOnDeliveryDisabled(!cityConfig.payment_home);
  }

  const handleClientUpdate = (value: any, field: string) => {
    const newClient = {...client as any};
    newClient[field] = value;

    setClient(newClient);
  };

  const handleProductSelection = (productId: number) => {
    const product = productsListFiltered.find((p: product) => p.id === productId) as product;

    const billProduct: BillProducts = {
      quantity: 1,
      productId: product.id,
      created_by: parseInt(localStorage.userID),
    };

    const newBillProducts = [...billProducts, billProduct];
    const filteredProducts = productsListFiltered.filter((p: product) => !newBillProducts.some((bp) => bp.productId == p.id))

    setCollectAmount(calculateCollectAmount(newBillProducts, products, localStorage.currency));
    setBillProducts(newBillProducts);
    setProductsListFiltered(filteredProducts)
  };

  const handleShowPreview = () => {
    setBillData(prepareBill());
    setShowShippingLabel(true);
  };

  const handleBillCreation = async () => {
    try {
      setSavingBill(true);

      const savedClient = await saveClient();

      if (!savedClient.id) {
        throw new Error("Se presentó un error al guardar los datos, intentelo nuevamente");
      }

      const formattedProducts = formatBillProducts({billProducts, products});
      const locationIndications = formatLocationIndications();

      let bill: Bill = {
        guia: "",
        delivery_value: 0,
        status: "Pendiente",
        payment_home: isCashOnDelivery,
        collect_value: isCashOnDelivery
            ? collectAmount
            : 0,
        authorized: true,
        store_ClientId: savedClient.id,
        email: userData?.email || '',
        storeId: parseInt(localStorage.storeID),
        origin_city: 6834,
        created_by: parseInt(localStorage.userID),
        bill_Products: billProducts,
        company_TransportId:
            selectedCourierId !== undefined
                ? selectedCourierId
                : 2,
        adress_indications: locationIndications,
        observaciones_Envio: `${formattedProducts} / ${deliveryComments}`,
        deliveryInWarehouse: isStorePickup,
        countryId: selectedCountryId,
      };

      if (selectedCourierId == 1) {
        bill.envia = prepareSender(
            store,
            formattedProducts,
            isCashOnDelivery,
            savedClient,
            deliveryComments
        );
      }

      if (isStorePickup) {
        bill.company_TransportId = 4;
        bill.collect_value = 0;
        bill.status = "Pendiente";
      }

      await axiosInstance.post(`/Bills`, bill)
          .then(() => {
            message.success("La factura se generó existosamente");
            setSelectedComponent("Sales");
          })
          .catch((err) => new Error(`La transportadora no permitió crear esta guia. ${err}`));
    } catch (error) {
      console.log(error);
      message.error(
          "Se peresentó un error al guardar los datos, intentelo nuevamente",
          5
      );
    } finally {
      setSavingBill(false);
    }
  }

  const handleProductSearch = (search: string) => {
    if (_.isEmpty(search)) {
      setProductsListFiltered(products);
    }

    const filteredProducts = products
        .filter((product) => product.name.toLowerCase().includes(search.toLowerCase()))
        .filter((p: product) => !billProducts.some((bp) => bp.productId == p.id))
    setProductsListFiltered(filteredProducts);
  };

  const handleCashOnDeliveryChange = () => {
    setIsCashOnDelivery(!isCashOnDelivery);
    if (!isCashOnDelivery) {
      setIsStorePickup(false);
    }
  }

  const handleStorePickupChange = () => {
    if (!isStorePickup) {
      setIsCashOnDelivery(false);
    }
    setIsStorePickup(!isStorePickup);
  }

  const updateBillProductsState = (value: any, field: string, index: number) => {
    const newBillProducts: BillProducts[] = [...billProducts];
    (newBillProducts[index] as any)[field] = value;

    setCollectAmount(calculateCollectAmount(newBillProducts, products, localStorage.currency));
    setBillProducts(newBillProducts);
  };

  const deleteProduct = (index: number) => {
    const newProducts: BillProducts[] = billProducts.filter((p, i) => i !== index);

    setCollectAmount(calculateCollectAmount(newProducts, products, localStorage.currency));
    setBillProducts(newProducts);
  };

  const openWarningNotification = (text: string) => {
    Modal.warning({
      title: <b>ADVERTENCIA</b>,
      content: (
          <div style={{textAlign: "center"}}>
            <img
                src="https://drophacking.blob.core.windows.net/images/Facturar/Advertencia.png"
                width={100}
                alt="Advertencia"/>
            <br/>
            {text}
          </div>
      )
    });
  };

  const UploadExcel = async (file: any) => {
    try {
      if (file.size > 1000000) {
        message.warning('El archivo no puede superar 1MB o 1.000 KB');
        return;
      }

      let formData = new FormData();
      formData.append('file', file);

      await axiosInstance.post(`/MassiveUpload/ReadExcelFile`, formData);

      message.success(`${file.name} se ha cargado correctamente`);
    } catch (error: any) {
      notification.error({message: error.response.data});

      if (error.response.data)
        console.error('Error al cargar el archivo', error.response.data);
      else
        message.error(`Error al cargar el archivo. ${error}`);
    }
  };

  const uploadExcel: any = {
    beforeUpload(file: any) {
      return new Promise(resolve => {
        const reader = new FileReader();
        UploadExcel(file)
        reader.readAsDataURL(file);
      });
    }
  };

  const downloadExcelFile = () => {
    axiosInstance.get(`/MassiveUpload?storeId=${userData?.storeID}`, {
      responseType: 'blob',
    })
      .then((response) => {
        const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Plantilla_Carga_Masiva.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        notification.error(error.errorMessage)
        console.error('Error al descargar el archivo', error);
      });

  }

  useEffect(() => {
    (async () => {
      try {
        await fetchCountries();
        await fetchProducts();
        await fetchCouriers();
        await fetchStore();
        await fetchPresales();

        await handleCountryChange(selectedCountryId);
      } catch (err: any) {
        console.log(err);
        message.error(`No se pudo cargar los datos de inicialización. ${err}`, 8);
      } finally {
        setBootstrapping(false);
      }
    })();

    return () => {
      setBootstrapping(false);
    };
  }, []);

  useEffect(() => {
    handleCityChange(undefined);
  }, [selectedCountryId]);

  useEffect(() => {
    setSelectedCourierId(undefined);
  }, [selectedCityId]);

  const columns = [
    {
      title: "Guia",
      dataIndex: "guia",
      key: "guia",
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Cantidad",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Producto",
      dataIndex: "product",
      key: "quaproductntity",
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date",
    },
  ];

  return (
      <Spin size="large" spinning={bootstrapping}>
        <div className="billing-container">

          <Row>
            <Col span={3}></Col>
            <Col span={20}>

              {/*--------------------------Datos del cliente----------------------------------*/}
              {currentStep == "Datos del Cliente" && (
                  <div>
                    <img
                        src="https://drophacking.blob.core.windows.net/images/Facturar/BannerDatosCliente.png"
                        width={"100%"}
                    />
                    <div className="fondo-blanco">
                      <Row>

                        <Col xs={24} sm={24} md={24} lg={22} xl={22} className="bg-1-sales">
                          <div onClick={() => {
                            setCurrentPreBills(preBills.filter(x => x.chanel == ChanelPreBill.Shopify))
                            setPopupWithScrollViewVisible(true);
                          }} className="menu-boton">
                            <img src="https://drophacking.blob.core.windows.net/images/Canales/icono-shopify.jpeg"/>
                            Shopify ({preBills.filter(x => x.chanel == ChanelPreBill.Shopify).length})
                          </div>
                          <div className="menu-boton" onClick={() => {
                            setCurrentPreBills(preBills.filter(x => x.chanel == ChanelPreBill.SmartChat))
                            setPopupWithScrollViewVisible(true);
                          }}>
                            <img sizes="l" src="https://drophacking.blob.core.windows.net/images/Canales/smarchat.png"/>
                            SmartChat ({preBills.filter(x => x.chanel == ChanelPreBill.SmartChat).length})
                          </div>
                        </Col>
                        <Popup
                            title="Pre ventas"
                            visible={popupWithScrollViewVisible && currentPreBills.length > 0}
                            onHiding={hide}
                            showCloseButton={true}
                        >
                          <div>
                            <TablePreBill data={currentPreBills}></TablePreBill>
                          </div>
                        </Popup>
                      </Row>
                      <Row>
                        <Col span={1}></Col>
                        <Col span={2}>
                          <Button onClick={downloadExcelFile} className="generic-btn"
                                  icon={<DownloadOutlined/>}>Descargar</Button>
                        </Col>
                        <Col span={1}></Col>
                        <Col span={3}>
                          <Upload {...uploadExcel}>
                            <Button className="generic-btn" icon={<UploadOutlined/>}>Carga Masiva</Button>
                          </Upload>
                        </Col>
                        <Col span={8} className="inputs-container">
                          <Input
                              placeholder="Número de Celular"
                              prefix={
                                <img
                                    src="https://drophacking.blob.core.windows.net/images/Facturar/ICONO-DE-CELULAR.png"
                                    className="form-icon"
                                />
                              }
                              onChange={(e) => setCellphone(e.target.value)}
                              onBlur={(e) => handleCellphoneChange(e.target.value)}
                              value={cellphone}
                              className="input"
                          />
                        </Col>
                        <Col span={8} className="inputs-container">
                          <Input
                              placeholder={"Nombre y Apellidos"}
                              prefix={
                                <img
                                    src="https://drophacking.blob.core.windows.net/images/Facturar/ICONO-DE-NOMBRE.png"
                                    className="form-icon"
                                />
                              }
                              onChange={(e) => setFullname(e.target.value)}
                              value={fullname}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12} className="inputs-container">
                          <Input
                              placeholder="Número de Identificación o NIT"
                              prefix={
                                <img
                                    src="https://drophacking.blob.core.windows.net/images/Facturar/ICONO-DE-NUMERO-DE-IDENTIFICACIÓN.png"
                                    className="form-icon"
                                />
                              }
                              onChange={(e) => setIdNumber(e.target.value)}
                              value={idNumber}
                          />
                        </Col>
                        <Col span={12} className="inputs-container">
                          <Input
                              placeholder="Correo Electrónico"
                              prefix={
                                <img
                                    src="https://drophacking.blob.core.windows.net/images/Facturar/ICONO-DE-CORREO-ELECTRONICO.png"
                                    className="form-icon"
                                />
                              }
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                          />
                        </Col>
                      </Row>

                      <div style={{marginTop: 15}}>
                        <div style={{color: "#021435", fontSize: 20}}>
                          HISTORIAL DE <b>COMPRA DEL CLIENTE</b>
                        </div>
                        {clientBills.length > 0 && (
                            <Table
                                size={"small"}
                                columns={columns}
                                dataSource={clientBills}
                                pagination={{defaultPageSize: 8}}
                            />
                        )}
                      </div>
                      <Row gutter={[16, 16]}>
                        <Col span={10}></Col>
                        {
                          <Col span={3}>
                            <Button className="generic-btn"
                                    disabled={!cellphone || !fullname || !idNumber}
                                    onClick={() => setCurrentStep("Localización y envío")}>
                              Siguiente {">"}
                            </Button>
                          </Col>
                        }
                      </Row>
                    </div>
                  </div>
              )}

              {/*--------------------------Localización y envío----------------------------------*/}
              {currentStep == "Localización y envío" && (
                  <div>
                    <img
                        src="https://drophacking.blob.core.windows.net/images/Facturar/BannerLocalization.png"
                        width={"100%"}
                    />
                    <div className="fondo-blanco">
                      <br/>
                      <Row>
                        <Col span={8} className="inputs-container">
                          <Select
                              className={"input"}
                              style={{width: "100%"}}
                              placeholder={"Selecciona un país"}
                              value={selectedCountryId}
                              disabled={true}
                              onChange={handleCountryChange}
                          >
                            {countries.map((country: country) => (
                                <Option key={country.id} value={country.id}>
                                  {country.name}
                                </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col span={8} className="inputs-container">
                          <Select
                              placeholder="Ciudad"
                              className="input"
                              value={selectedCityId}
                              onChange={handleCityChange}
                              showSearch
                              disabled={!selectedCountryId}
                              filterOption={(input, option) =>
                                  (option!.children as unknown as string)
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                              }
                          >
                            {cities.map((city) => (
                                <Option value={city.id} key={city.id}>
                                  {city.name}
                                </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col span={8} className="inputs-container">
                          <Select
                              style={{width: "100%"}}
                              placeholder={"Selecciona una transportadora"}
                              value={selectedCourierId}
                              onChange={handleCourierChange}
                          >
                            {couriers.map((courier: company_transport) => (
                                <Option
                                    key={courier.id}
                                    value={courier.id}
                                    disabled={!courier.cities_Company_Transports.find((c) => c.cityId === selectedCityId)}
                                >
                                  <img
                                      src={courier.iconPath}
                                      style={{width: 40, marginRight: 20}}
                                  />
                                  {courier.name}
                                </Option>
                            ))}
                          </Select>
                        </Col>
                      </Row>
                      {localStorage.currency == "COP" ? (
                          <Row>
                            <Col span={3} className="inputs-container">
                              <Select
                                  style={{width: "100%"}}
                                  placeholder="Calle"
                                  value={client.carrera}
                                  onChange={(text) =>
                                      handleClientUpdate(text, "carrera")
                                  }
                              >
                                <Option value="" key="-">
                                  -
                                </Option>
                                <Option value="Avenida" key="Avenida">
                                  Avenida
                                </Option>
                                <Option value="Autopista" key="Autopista">
                                  Autopista
                                </Option>
                                <Option value="Calle" key="Calle">
                                  Calle
                                </Option>
                                <Option value="Carrera" key="Carrera">
                                  Carrera
                                </Option>
                                <Option value="Circular" key="Circular">
                                  Circular
                                </Option>
                                <Option value="Diagonal" key="Diagonal">
                                  Diagonal
                                </Option>
                                <Option value="Transversal" key="Transversal">
                                  Transversal
                                </Option>
                                <Option value="Troncal" key="Troncal">
                                  Troncal
                                </Option>
                              </Select>
                            </Col>
                            <Col span={3} className="inputs-container">
                              <Input
                                  placeholder="Ej: 85"
                                  value={client.adress_field_1}
                                  onChange={(e) =>
                                      handleClientUpdate(e.target.value, "adress_field_1")
                                  }
                              />
                            </Col>
                            <Col span={3} className="inputs-container">
                              <Select
                                  style={{width: "100%"}}
                                  placeholder="Ej: A / aa"
                                  value={client.adress_field_2}
                                  onChange={(text) =>
                                      handleClientUpdate(text, "adress_field_2")
                                  }
                              >
                                <Option value="" key="-">
                                  -
                                </Option>
                                <Option value="A" key="A">
                                  A
                                </Option>
                                <Option value="AA" key="AA">
                                  AA
                                </Option>
                                <Option value="AB" key="AB">
                                  AB
                                </Option>
                                <Option value="AC" key="AC">
                                  AC
                                </Option>
                                <Option value="AD" key="AD">
                                  AD
                                </Option>
                                <Option value="AE" key="AE">
                                  AE
                                </Option>
                                <Option value="AF" key="AF">
                                  AF
                                </Option>
                                <Option value="AG" key="AG">
                                  AG
                                </Option>
                                <Option value="AH" key="AH">
                                  AH
                                </Option>
                                <Option value="AN" key="AN">
                                  AN
                                </Option>
                                <Option value="AW" key="AW">
                                  AW
                                </Option>
                                <Option value="B" key="B">
                                  B
                                </Option>
                                <Option value="BB" key="BB">
                                  BB
                                </Option>
                                <Option value="BA" key="BA">
                                  BA
                                </Option>
                                <Option value="BC" key="BC">
                                  BC
                                </Option>
                                <Option value="BD" key="BD">
                                  BD
                                </Option>
                                <Option value="BE" key="BE">
                                  BE
                                </Option>
                                <Option value="BF" key="BF">
                                  BF
                                </Option>
                                <Option value="BG" key="BG">
                                  BG
                                </Option>
                                <Option value="BH" key="BH">
                                  BH
                                </Option>
                                <Option value="BN" key="BN">
                                  BN
                                </Option>
                                <Option value="C" key="C">
                                  C
                                </Option>
                                <Option value="CC" key="CC">
                                  CC
                                </Option>
                                <Option value="CA" key="CA">
                                  CA
                                </Option>
                                <Option value="CB" key="CB">
                                  CB
                                </Option>
                                <Option value="CD" key="CD">
                                  CD
                                </Option>
                                <Option value="CE" key="CE">
                                  CE
                                </Option>
                                <Option value="CF" key="CF">
                                  CF
                                </Option>
                                <Option value="CG" key="CG">
                                  CG
                                </Option>
                                <Option value="CH" key="CH">
                                  CH
                                </Option>
                                <Option value="D" key="D">
                                  D
                                </Option>
                                <Option value="DD" key="DD">
                                  DD
                                </Option>
                                <Option value="DA" key="DA">
                                  DA
                                </Option>
                                <Option value="DB" key="DB">
                                  DB
                                </Option>
                                <Option value="DC" key="DC">
                                  DC
                                </Option>
                                <Option value="DE" key="DE">
                                  DE
                                </Option>
                                <Option value="DF" key="DF">
                                  DF
                                </Option>
                                <Option value="DG" key="DG">
                                  DG
                                </Option>
                                <Option value="DH" key="DH">
                                  DH
                                </Option>
                                <Option value="E" key="E">
                                  E
                                </Option>
                                <Option value="EE" key="EE">
                                  EE
                                </Option>
                                <Option value="EA" key="EA">
                                  EA
                                </Option>
                                <Option value="EB" key="EB">
                                  EB
                                </Option>
                                <Option value="EC" key="EC">
                                  EC
                                </Option>
                                <Option value="ED" key="ED">
                                  ED
                                </Option>
                                <Option value="EF" key="EF">
                                  EF
                                </Option>
                                <Option value="EG" key="EG">
                                  EG
                                </Option>
                                <Option value="EH" key="EH">
                                  EH
                                </Option>
                                <Option value="F" key="F">
                                  F
                                </Option>
                                <Option value="FF" key="FF">
                                  FF
                                </Option>
                                <Option value="FA" key="FA">
                                  FA
                                </Option>
                                <Option value="FB" key="FB">
                                  FB
                                </Option>
                                <Option value="FC" key="FC">
                                  FC
                                </Option>
                                <Option value="FD" key="FD">
                                  FD
                                </Option>
                                <Option value="FE" key="FE">
                                  FE
                                </Option>
                                <Option value="FG" key="FG">
                                  FG
                                </Option>
                                <Option value="FH" key="FH">
                                  FH
                                </Option>
                                <Option value="G" key="G">
                                  G
                                </Option>
                                <Option value="GG" key="GG">
                                  GG
                                </Option>
                                <Option value="GA" key="GA">
                                  GA
                                </Option>
                                <Option value="GB" key="GB">
                                  GB
                                </Option>
                                <Option value="GC" key="GC">
                                  GC
                                </Option>
                                <Option value="GD" key="GD">
                                  GD
                                </Option>
                                <Option value="GE" key="GE">
                                  GE
                                </Option>
                                <Option value="GF" key="GF">
                                  GF
                                </Option>
                                <Option value="GH" key="GH">
                                  GH
                                </Option>
                                <Option value="GN" key="GN">
                                  GN
                                </Option>
                                <Option value="H" key="H">
                                  H
                                </Option>
                                <Option value="HH" key="HH">
                                  HH
                                </Option>
                                <Option value="HA" key="HA">
                                  HA
                                </Option>
                                <Option value="HB" key="HB">
                                  HB
                                </Option>
                                <Option value="HC" key="HC">
                                  HC
                                </Option>
                                <Option value="HD" key="HD">
                                  HD
                                </Option>
                                <Option value="HE" key="HE">
                                  HE
                                </Option>
                                <Option value="HF" key="HF">
                                  HF
                                </Option>
                                <Option value="HG" key="HG">
                                  HG
                                </Option>
                                <Option value="I" key="I">
                                  I
                                </Option>
                                <Option value="J" key="J">
                                  J
                                </Option>
                                <Option value="K" key="K">
                                  K
                                </Option>
                                <Option value="L" key="L">
                                  L
                                </Option>
                                <Option value="M" key="M">
                                  M
                                </Option>
                                <Option value="N" key="N">
                                  N
                                </Option>
                                <Option value="O" key="O">
                                  O
                                </Option>
                                <Option value="P" key="P">
                                  P
                                </Option>
                                <Option value="Q" key="Q">
                                  Q
                                </Option>
                                <Option value="R" key="R">
                                  R
                                </Option>
                                <Option value="S" key="S">
                                  S
                                </Option>
                                <Option value="T" key="T">
                                  T
                                </Option>
                                <Option value="U" key="U">
                                  U
                                </Option>
                                <Option value="V" key="V">
                                  V
                                </Option>
                                <Option value="W" key="W">
                                  W
                                </Option>
                                <Option value="X" key="X">
                                  X
                                </Option>
                                <Option value="Y" key="Y">
                                  Y
                                </Option>
                                <Option value="Z" key="Z">
                                  Z
                                </Option>
                              </Select>
                            </Col>
                            <Col span={3} className="inputs-container">
                              <Select
                                  style={{width: "100%"}}
                                  placeholder="Sur / Norte"
                                  value={client.adress_field_3}
                                  onChange={(text) =>
                                      handleClientUpdate(text, "adress_field_3")
                                  }
                              >
                                <Option value="" key="-">
                                  -
                                </Option>
                                <Option value="Sur" key="Sur">
                                  Sur
                                </Option>
                                <Option value="Bis" key="Bis">
                                  Bis
                                </Option>
                                <Option value="Bis1" key="Bis1">
                                  Bis1
                                </Option>
                                <Option value="Bis2" key="Bis2">
                                  Bis2
                                </Option>
                                <Option value="Oeste" key="Oeste">
                                  Oeste
                                </Option>
                                <Option value="Este" key="Este">
                                  Este
                                </Option>
                                <Option value="Norte" key="Norte">
                                  Norte
                                </Option>
                                <Option value="Avenida" key="Avenida">
                                  Avenida
                                </Option>
                                <Option value="Autopista" key="Autopista">
                                  Autopista
                                </Option>
                                <Option value="Calle" key="Calle">
                                  Calle
                                </Option>
                                <Option value="Carrera" key="Carrera">
                                  Carrera
                                </Option>
                                <Option value="Circular" key="Circular">
                                  Circular
                                </Option>
                                <Option value="Diagonal" key="Diagonal">
                                  Diagonal
                                </Option>
                                <Option value="Transversal" key="Transversal">
                                  Transversal
                                </Option>
                                <Option value="Troncal" key="Troncal">
                                  Troncal
                                </Option>
                              </Select>
                            </Col>
                            <Col span={3} className="inputs-container">
                              <Input
                                  placeholder="# 35"
                                  value={client.adress_field_4}
                                  onChange={(e) =>
                                      handleClientUpdate(e.target.value, "adress_field_4")
                                  }
                              />
                            </Col>
                            <Col span={3} className="inputs-container">
                              <Select
                                  style={{width: "100%"}}
                                  placeholder="Ej: F"
                                  value={client.adress_field_5}
                                  onChange={(text) =>
                                      handleClientUpdate(text, "adress_field_5")
                                  }
                              >
                                <Option value="" key="-">
                                  -
                                </Option>
                                <Option value="A" key="A">
                                  A
                                </Option>
                                <Option value="AA" key="AA">
                                  AA
                                </Option>
                                <Option value="AB" key="AB">
                                  AB
                                </Option>
                                <Option value="AC" key="AC">
                                  AC
                                </Option>
                                <Option value="AD" key="AD">
                                  AD
                                </Option>
                                <Option value="AE" key="AE">
                                  AE
                                </Option>
                                <Option value="AF" key="AF">
                                  AF
                                </Option>
                                <Option value="AG" key="AG">
                                  AG
                                </Option>
                                <Option value="AH" key="AH">
                                  AH
                                </Option>
                                <Option value="AN" key="AN">
                                  AN
                                </Option>
                                <Option value="AW" key="AW">
                                  AW
                                </Option>
                                <Option value="B" key="B">
                                  B
                                </Option>
                                <Option value="BB" key="BB">
                                  BB
                                </Option>
                                <Option value="BA" key="BA">
                                  BA
                                </Option>
                                <Option value="BC" key="BC">
                                  BC
                                </Option>
                                <Option value="BD" key="BD">
                                  BD
                                </Option>
                                <Option value="BE" key="BE">
                                  BE
                                </Option>
                                <Option value="BF" key="BF">
                                  BF
                                </Option>
                                <Option value="BG" key="BG">
                                  BG
                                </Option>
                                <Option value="BH" key="BH">
                                  BH
                                </Option>
                                <Option value="BN" key="BN">
                                  BN
                                </Option>
                                <Option value="C" key="C">
                                  C
                                </Option>
                                <Option value="CC" key="CC">
                                  CC
                                </Option>
                                <Option value="CA" key="CA">
                                  CA
                                </Option>
                                <Option value="CB" key="CB">
                                  CB
                                </Option>
                                <Option value="CD" key="CD">
                                  CD
                                </Option>
                                <Option value="CE" key="CE">
                                  CE
                                </Option>
                                <Option value="CF" key="CF">
                                  CF
                                </Option>
                                <Option value="CG" key="CG">
                                  CG
                                </Option>
                                <Option value="CH" key="CH">
                                  CH
                                </Option>
                                <Option value="D" key="D">
                                  D
                                </Option>
                                <Option value="DD" key="DD">
                                  DD
                                </Option>
                                <Option value="DA" key="DA">
                                  DA
                                </Option>
                                <Option value="DB" key="DB">
                                  DB
                                </Option>
                                <Option value="DC" key="DC">
                                  DC
                                </Option>
                                <Option value="DE" key="DE">
                                  DE
                                </Option>
                                <Option value="DF" key="DF">
                                  DF
                                </Option>
                                <Option value="DG" key="DG">
                                  DG
                                </Option>
                                <Option value="DH" key="DH">
                                  DH
                                </Option>
                                <Option value="E" key="E">
                                  E
                                </Option>
                                <Option value="EE" key="EE">
                                  EE
                                </Option>
                                <Option value="EA" key="EA">
                                  EA
                                </Option>
                                <Option value="EB" key="EB">
                                  EB
                                </Option>
                                <Option value="EC" key="EC">
                                  EC
                                </Option>
                                <Option value="ED" key="ED">
                                  ED
                                </Option>
                                <Option value="EF" key="EF">
                                  EF
                                </Option>
                                <Option value="EG" key="EG">
                                  EG
                                </Option>
                                <Option value="EH" key="EH">
                                  EH
                                </Option>
                                <Option value="F" key="F">
                                  F
                                </Option>
                                <Option value="FF" key="FF">
                                  FF
                                </Option>
                                <Option value="FA" key="FA">
                                  FA
                                </Option>
                                <Option value="FB" key="FB">
                                  FB
                                </Option>
                                <Option value="FC" key="FC">
                                  FC
                                </Option>
                                <Option value="FD" key="FD">
                                  FD
                                </Option>
                                <Option value="FE" key="FE">
                                  FE
                                </Option>
                                <Option value="FG" key="FG">
                                  FG
                                </Option>
                                <Option value="FH" key="FH">
                                  FH
                                </Option>
                                <Option value="G" key="G">
                                  G
                                </Option>
                                <Option value="GG" key="GG">
                                  GG
                                </Option>
                                <Option value="GA" key="GA">
                                  GA
                                </Option>
                                <Option value="GB" key="GB">
                                  GB
                                </Option>
                                <Option value="GC" key="GC">
                                  GC
                                </Option>
                                <Option value="GD" key="GD">
                                  GD
                                </Option>
                                <Option value="GE" key="GE">
                                  GE
                                </Option>
                                <Option value="GF" key="GF">
                                  GF
                                </Option>
                                <Option value="GH" key="GH">
                                  GH
                                </Option>
                                <Option value="GN" key="GN">
                                  GN
                                </Option>
                                <Option value="H" key="H">
                                  H
                                </Option>
                                <Option value="HH" key="HH">
                                  HH
                                </Option>
                                <Option value="HA" key="HA">
                                  HA
                                </Option>
                                <Option value="HB" key="HB">
                                  HB
                                </Option>
                                <Option value="HC" key="HC">
                                  HC
                                </Option>
                                <Option value="HD" key="HD">
                                  HD
                                </Option>
                                <Option value="HE" key="HE">
                                  HE
                                </Option>
                                <Option value="HF" key="HF">
                                  HF
                                </Option>
                                <Option value="HG" key="HG">
                                  HG
                                </Option>
                                <Option value="I" key="I">
                                  I
                                </Option>
                                <Option value="J" key="J">
                                  J
                                </Option>
                                <Option value="K" key="K">
                                  K
                                </Option>
                                <Option value="L" key="L">
                                  L
                                </Option>
                                <Option value="M" key="M">
                                  M
                                </Option>
                                <Option value="N" key="N">
                                  N
                                </Option>
                                <Option value="O" key="O">
                                  O
                                </Option>
                                <Option value="P" key="P">
                                  P
                                </Option>
                                <Option value="Q" key="Q">
                                  Q
                                </Option>
                                <Option value="R" key="R">
                                  R
                                </Option>
                                <Option value="S" key="S">
                                  S
                                </Option>
                                <Option value="T" key="T">
                                  T
                                </Option>
                                <Option value="U" key="U">
                                  U
                                </Option>
                                <Option value="V" key="V">
                                  V
                                </Option>
                                <Option value="W" key="W">
                                  W
                                </Option>
                                <Option value="X" key="X">
                                  X
                                </Option>
                                <Option value="Y" key="Y">
                                  Y
                                </Option>
                                <Option value="Z" key="Z">
                                  Z
                                </Option>
                              </Select>
                            </Col>
                            <Col span={3} className="inputs-container">
                              <Select
                                  style={{width: "100%"}}
                                  placeholder="Sur / Norte"
                                  value={client.adress_field_6}
                                  onChange={(text) =>
                                      handleClientUpdate(text, "adress_field_6")
                                  }
                              >
                                <Option value="" key="-">
                                  -
                                </Option>
                                <Option value="Sur" key="Sur">
                                  Sur
                                </Option>
                                <Option value="Bis" key="Bis">
                                  Bis
                                </Option>
                                <Option value="Bis1" key="Bis1">
                                  Bis1
                                </Option>
                                <Option value="Bis2" key="Bis2">
                                  Bis2
                                </Option>
                                <Option value="Oeste" key="Oeste">
                                  Oeste
                                </Option>
                                <Option value="Este" key="Este">
                                  Este
                                </Option>
                                <Option value="Norte" key="Norte">
                                  Norte
                                </Option>
                                <Option value="Avenida" key="Avenida">
                                  Avenida
                                </Option>
                                <Option value="Autopista" key="Autopista">
                                  Autopista
                                </Option>
                                <Option value="Calle" key="Calle">
                                  Calle
                                </Option>
                                <Option value="Carrera" key="Carrera">
                                  Carrera
                                </Option>
                                <Option value="Circular" key="Circular">
                                  Circular
                                </Option>
                                <Option value="Diagonal" key="Diagonal">
                                  Diagonal
                                </Option>
                                <Option value="Transversal" key="Transversal">
                                  Transversal
                                </Option>
                                <Option value="Troncal" key="Troncal">
                                  Troncal
                                </Option>
                              </Select>
                            </Col>
                            <Col span={3} className="inputs-container">
                              <Input
                                  placeholder="Ej: 45"
                                  value={client.adress_field_7}
                                  onChange={(e) =>
                                      handleClientUpdate(e.target.value, "adress_field_7")
                                  }
                              />
                            </Col>
                            <Col span={4} className="inputs-container">
                              <Select
                                  style={{width: "100%"}}
                                  placeholder="Bloque / Torre"
                                  value={client.adress_field_10}
                                  onChange={(text) =>
                                      handleClientUpdate(text, "adress_field_10")
                                  }
                              >
                                <Option value="" key="-">
                                  -
                                </Option>
                                <Option value="Bloque" key="Bloque">
                                  Bloque
                                </Option>
                                <Option value="Torre" key="Torre">
                                  Torre
                                </Option>
                                <Option value="Condominio" key="Condominio">
                                  Condominio
                                </Option>
                                <Option value="Manzana" key="Manzana">
                                  Manzana
                                </Option>
                                <Option value="Fase" key="Fase">
                                  Fase
                                </Option>
                                <Option value="Etapa" key="Etapa">
                                  Etapa
                                </Option>
                                <Option value="Piso" key="Piso">
                                  Piso
                                </Option>
                                <Option value="Interior" key="Interior">
                                  Interior
                                </Option>
                              </Select>
                            </Col>
                            <Col span={2} className="inputs-container">
                              <Input
                                  placeholder="3"
                                  value={client.adress_field_11}
                                  onChange={(e) =>
                                      handleClientUpdate(e.target.value, "adress_field_11")
                                  }
                              />
                            </Col>
                            <Col span={4} className="inputs-container">
                              <Select
                                  style={{width: "100%"}}
                                  placeholder="Casa / Apartamento"
                                  value={client.adress_field_8}
                                  onChange={(text) =>
                                      handleClientUpdate(text, "adress_field_8")
                                  }
                              >
                                <Option value="" key="-">
                                  -
                                </Option>
                                <Option value="Casa" key="Casa">
                                  Casa
                                </Option>
                                <Option value="Apartamento" key="Apartamento">
                                  Apartamento
                                </Option>
                                <Option value="Interior" key="Interior">
                                  Interior
                                </Option>
                                <Option value="Local" key="Local">
                                  Local
                                </Option>
                                <Option value="Oficina" key="Oficina">
                                  Oficina
                                </Option>
                                <Option value="Bodega" key="Bodega">
                                  Bodega
                                </Option>
                                <Option value="Consultorio" key="Consultorio">
                                  Consultorio
                                </Option>
                                <Option value="Esquina" key="Esquina">
                                  Esquina
                                </Option>
                                <Option value="Habitación" key="Habitación">
                                  Habitación
                                </Option>
                                <Option value="Finca" key="Finca">
                                  Finca
                                </Option>
                                <Option value="Hotel" key="Hotel">
                                  Hotel
                                </Option>
                                <Option value="Hospital" key="Hospital">
                                  Hospital
                                </Option>
                                <Option value="Colegio" key="Colegio">
                                  Colegio
                                </Option>
                                <Option value="Lote" key="Lote">
                                  Lote
                                </Option>
                                <Option value="Universidad" key="Universidad">
                                  Universidad
                                </Option>
                              </Select>
                            </Col>
                            <Col span={3} className="inputs-container">
                              <Input
                                  placeholder="Interior"
                                  type="number"
                                  value={client.adress_field_9}
                                  onChange={(e) =>
                                      handleClientUpdate(e.target.value, "adress_field_9")
                                  }
                              />
                            </Col>
                            <Col span={4} className="inputs-container">
                              <Input
                                  placeholder="Barrio"
                                  maxLength={30}
                                  value={client.neighborhood}
                                  onChange={(e) =>
                                      handleClientUpdate(e.target.value, "neighborhood")
                                  }
                              />
                              <div style={{fontSize: 11, color: "grey"}}>
                                Máximo 30 caracteres ({client.neighborhood.length}{" "}
                                / 30)
                              </div>
                            </Col>
                            <Col span={7} className="inputs-container">
                              <Input
                                  placeholder="Edificio / Unidad / Conjunto"
                                  value={client.building}
                                  onChange={(e) =>
                                      handleClientUpdate(e.target.value, "building")
                                  }
                              />
                            </Col>
                          </Row>
                      ) : (
                          <Col span={24} className="inputs-container">
                            <Input
                                placeholder="Dirección"
                                value={client.adress_field_1}
                                onChange={(e) =>
                                    handleClientUpdate(e.target.value, "adress_field_1")
                                }
                            />
                          </Col>
                      )}
                      <Row>
                        { selectedCityId === CITIES.OTHER_CITY.id && (
                            <>
                              <Col span={3} className="inputs-container">
                                <Input
                                    placeholder="Ciudad"
                                    maxLength={40}
                                    value={city}
                                    onChange={(e) => setCity(e.target.value)}
                                />
                                <div style={{fontSize: 12, color: "grey"}}>
                                  Máximo 40 caracteres (
                                  {city.length} / 40)
                                </div>
                              </Col>
                              <Col span={3} className="inputs-container">
                                <Input
                                    placeholder="Departamento"
                                    maxLength={40}
                                    value={province}
                                    onChange={(e) => setProvince(e.target.value)}
                                />
                                <div style={{fontSize: 12, color: "grey"}}>
                                  Máximo 40 caracteres (
                                  {province.length} / 40)
                                </div>
                              </Col>
                            </>
                        )}
                        <Col span={selectedCityId === CITIES.OTHER_CITY.id ? 9 : 12} className="inputs-container">
                          <Input
                              placeholder=" Indicaciones Adicionales"
                              maxLength={70}
                              value={client.adress_indications}
                              onChange={(e) =>
                                  handleClientUpdate(e.target.value, "adress_indications")
                              }
                          />
                          <div style={{fontSize: 12, color: "grey"}}>
                            Máximo 70 caracteres (
                            {client.adress_indications.length} / 70)
                          </div>
                        </Col>
                        <Col span={selectedCityId === CITIES.OTHER_CITY.id ? 9 : 12} className="inputs-container">
                          <Input
                              placeholder="Observaciones de envío"
                              maxLength={50}
                              value={deliveryComments}
                              onChange={(text) =>
                                  setDeliveryComments(text.target.value)
                              }
                          />
                          <div style={{fontSize: 12, color: "grey"}}>
                            Máximo 50 caracteres ({deliveryComments.length} / 50)
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12}>
                          <div style={{color: "#021435", fontSize: 15}}>
                            <b>DIRECCIÓN</b>
                          </div>
                          <br/>
                          <b>
                            {`${client.carrera} ${client.adress_field_1} 
                ${client.adress_field_2} ${client.adress_field_3} 
                ${client.adress_field_1 !== "" ? "#" : ""} 
                ${client.adress_field_4} ${client.adress_field_5} ${client.adress_field_6
                            }
                ${client.adress_field_7 !== "" ? "-" : ""} ${client.adress_field_7
                            }`}
                          </b>
                          {selectedCityId === CITIES.OTHER_CITY.id && (
                              <>
                                <br/>
                                <b>{city} - {province}</b>
                              </>
                          )}
                        </Col>
                        <Col span={12}>
                          <b>
                            {`${client.adress_field_10} ${client.adress_field_11} 
                  ${client.adress_field_8} ${client.adress_field_9}`}
                          </b>
                          <br/>
                          <b>
                            {client.building !== ""
                                ? "Edificio / Unidad / Conjunto:"
                                : ""}{" "}
                            {client.building}
                          </b>
                          <br/>
                          <b>
                            {(client.neighborhood !== "" && selectedCountryId === 1) ? "Barrio:" : ""}{" "}
                            {selectedCountryId === 1 && (client.neighborhood)}
                          </b>
                          <br/>
                          <b>{client.adress_indications}</b>
                        </Col>
                      </Row>

                      <Row gutter={[16, 16]}>
                        <Col span={9}></Col>
                        <Col span={3}>
                          <Button
                              className="generic-btn"
                              onClick={() => setCurrentStep("Datos del Cliente")}
                          >
                            {"<"} Atrás
                          </Button>
                        </Col>
                        <Col span={3}>
                          <Button
                              disabled={!selectedCourierId
                                  || !validAddress(client as StoreClient)
                                  || (selectedCityId === CITIES.OTHER_CITY.id && !city)
                                  || (selectedCityId === CITIES.OTHER_CITY.id && !province)
                              }
                              className="generic-btn"
                              onClick={() => setCurrentStep("Productos")}
                          >
                            Siguiente {">"}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
              )}

              {/*--------------------------Productos----------------------------------*/}
              {currentStep == "Productos" && (
                  <div>
                    <img
                        src="https://drophacking.blob.core.windows.net/images/Facturar/BannerProductos.png"
                        width={"100%"}
                    />
                    <div className="fondo-blanco">
                      <br/>
                      <div style={{color: "#021435", fontSize: 25}}>
                        DETALLES <b>DEL ENVÍO</b>
                      </div>
                      <br/>
                      <Row>
                        <Col span={12} className="inputs-container">
                          <Select
                              style={{width: "100%", marginBottom: 20}}
                              placeholder="Selecciona un producto"
                              defaultActiveFirstOption={false}
                              showSearch
                              showArrow={false}
                              filterOption={false}
                              onSearch={handleProductSearch}
                              onChange={handleProductSelection}
                              notFoundContent={null}
                          >
                            {productsListFiltered.map(product => (
                                <Option key={product.id} value={product.id} product={product}>
                                  <Row>
                                    <Col span={24}>{product.name}</Col>
                                  </Row>
                                </Option>
                            ))}
                          </Select>
                        </Col>
                        <Col span={12} className="inputs-contaiFner">
                          {
                            (() => {
                              const rows = [];

                              if (billProducts.length) {
                                rows.push((
                                    <Row style={{fontWeight: "bold", marginBottom: 10}}>
                                      <Col span={14}>Producto</Col>
                                      <Col span={4}>Valor</Col>
                                      <Col span={4}>Cantidad</Col>
                                    </Row>
                                ))

                                const priceSelector = priceSelectorByCurrency(localStorage.currency);
                                billProducts.forEach((billProduct, index) => {
                                  const product = products.find((p) => p.id == billProduct.productId) as product;

                                  rows.push((
                                      <>
                                        <Row
                                            gutter={[16, 16]}
                                            style={{
                                              fontSize: 12,
                                              paddingBottom: 3,
                                              marginBottom: 3,
                                              borderBottom: "1px solid lightgray",
                                            }}
                                        >
                                          <Col span={14}>{product.name}</Col>
                                          <Col
                                              span={4}>{priceSelector(product).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Col>
                                          <Col span={4}>
                                            <InputNumber
                                                onChange={(e) =>
                                                    updateBillProductsState(e, "quantity", index)
                                                }
                                                value={billProduct.quantity}
                                                style={{
                                                  width: "90%",
                                                  borderRadius: "10px 0px 0px 10px",
                                                }}
                                                min={1}
                                            />
                                          </Col>
                                          <Col span={2}>
                                            <Popconfirm
                                                placement="top"
                                                title={"¿ Eliminar este producto ?"}
                                                onConfirm={() => deleteProduct(index)}
                                                okText="Sí"
                                                cancelText="No"
                                            >
                                              <DeleteOutlined style={{color: "red"}}/>
                                            </Popconfirm>
                                          </Col>
                                        </Row>
                                      </>
                                  ))
                                })

                              }

                              return rows;
                            })()
                          }
                        </Col>
                      </Row>

                      <Row>
                        <Col span={12} className="inputs-container">
                          <b>Valor a recaudar:</b>
                          <InputNumber
                              style={{
                                width: "100%",
                                borderRadius: "10px 0px 0px 10px",
                              }}
                              formatter={(value) =>
                                  `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }
                              prefix={
                                <img
                                    src="https://drophacking.blob.core.windows.net/images/Facturar/ICONO-DE-VALOR.png"
                                    className="form-icon"
                                />
                              }
                              value={isCashOnDelivery ? collectAmount : 0}
                              parser={(value: any) => value.replace(/\$\s?|(,*)/g, "")}
                              onChange={(e: number | null) => setCollectAmount(e ?? 0)}
                              disabled={!isCashOnDelivery}
                          />
                        </Col>
                        {selectedCourierId !== COURIERS.INTER_RAPIDISIMO.id && (
                            <Col span={12} className="inputs-container">
                              <Col span={10}>
                                Pago contra entrega:&nbsp;
                                <Switch
                                    size="small"
                                    checked={isCashOnDelivery}
                                    onChange={handleCashOnDeliveryChange}
                                    disabled={isCasOnDeliveryDisabled}
                                />
                              </Col>
                              <Col span={10}>
                                Recoger en CRECEL:&nbsp;
                                <Switch
                                    size="small"
                                    checked={isStorePickup}
                                    onChange={handleStorePickupChange}
                                    disabled={selectedCityId != 6834}
                                />
                              </Col>
                            </Col>
                        )}
                        {selectedCourierId === COURIERS.INTER_RAPIDISIMO.id && (
                            <RadioGroup onChange={handleCashOnDeliveryChange} value={isCashOnDelivery}>
                              <Radio
                                  value={true}
                                  className="radio-vertical">
                                Pago en casa
                              </Radio>
                              <Radio
                                  value={false}
                                  className="radio-vertical">
                                Pago de contado
                              </Radio>
                            </RadioGroup>
                        )}
                      </Row>

                      <Row gutter={[16, 16]}>
                        <Col span={6}></Col>
                        <Col span={3}>
                          <div
                              className="generic-btn"
                              onClick={() => setCurrentStep("Localización y envío")}
                          >
                            {"<"} Atrás
                          </div>
                        </Col>
                        <Col span={6}>
                          <div
                              style={{backgroundColor: "gray"}}
                              className="generic-btn"
                              onClick={handleShowPreview}
                          >
                            Vista previa de la guía
                          </div>
                        </Col>

                        {billProducts.length > 0 && (
                            <Col span={3}>
                              <Spin tip="Guardando..." spinning={savingBill}>
                                <div className="generic-btn" onClick={() => handleBillCreation()}>
                                  Enviar {">"}
                                </div>
                              </Spin>
                            </Col>
                        )}
                      </Row>
                    </div>
                  </div>
              )}
            </Col>
          </Row>
          {showShippingLabel && (
              <ModalDetalleGuia
                  visible={showShippingLabel}
                  setVisible={setShowShippingLabel}
                  guia={""}
                  BillData={billData}
              />
          )}
        </div>
      </Spin>
  );
};

export default GenerateBill;

