import { Badge } from "antd";
import moment from "moment";
import { useState } from "react";
import { axiosInstance } from "../commons/services/axiosConfig";
import { Bill } from "../Models/Bill";
import { city } from "../Models/city.model";
import { company_transport } from "../Models/company_Transport";
import { BillProducts, product } from "../Models/Products";
import { BillByStatus, LabelModel } from "../Models/Warehouse";
import {formatAddress, formatIndications} from "../commons/utils/addressUtils";
import {formatBillProducts} from "../commons/utils/billUtils";
import {safeEndsWith} from "../commons/utils/stringUtils";

const useWarehouse = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataLabels, setdataLabels] = useState<Array<Bill>>([]);
  const [citiesList, setCitiesList] = useState<Array<city>>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataGuias, setdataGuias] = useState<Array<Bill>>([]);
  const [labelsList, setLabelsList] = useState<Array<LabelModel>>([]);
  const [billsArrayList, setBillsArrayList] = useState<Array<Bill>>([]);
  const [ModalVisible, setModalVisible] = useState(false);
  const [ModalDetailBillVisible, setModalDetailBillVisible] = useState<boolean>(false);
  const [selectedGuia, setSelectedGuia] = useState<string>('');
  const [printMode, setPrintMode] = useState<'Rotulos' | 'Guias'>('Rotulos');
  const [selectedCompanyTransport, setSelectedCompanyTransport] = useState<number>(-1);
  const [companyTransportList, setCompanyTransportList] = useState<
    Array<company_transport>
  >([]);
  const [productList, setProductList] = useState<Array<product>>([]);

  const getPendingBillsByStatus = async (status: string) => {
    let array: any = [];
    setLoading(true);
    let bills = await axiosInstance.get<Bill[]>(
      `/Bills/GetBillsByStatus?Status=${status}`
    );
    bills.data.map((x: Bill) => {
      x.created_at = moment(x.created_at).format("YYYY-MM-DD HH:mm");
      x.key = x.id;
      array.push(x);
    });
    //Pendientes por anular
    let AnulationBills = await axiosInstance.get<Bill[]>(
      `/Bills/GetBillsByStatus?Status=Anulación Solicitada`
    );
    AnulationBills.data.map((x: Bill) => {
      x.created_at = moment(x.created_at).format("YYYY-MM-DD HH:mm");
      x.key = x.id;
      array.push(x);
    });
    setLoading(false);
    setdataLabels(array.reverse());
  };

  const getProductList = async () => {
    var resp = await axiosInstance.get<product[]>(`/Products`)
    if (resp.status == 200) {
      setProductList(resp.data);
    }
  }

  const getCompanyTransports = async () => {
    var resp = await axiosInstance.get<company_transport[]>(`/CompanyTransports`);
    if (resp.status == 200) {
      setCompanyTransportList(resp.data);
    }
  };

  const getCities = async () => {
    var resp = await axiosInstance.get<city[]>(`/Cities`);
    if (resp.status == 200) {
      setCitiesList(resp.data);
    }
  }

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: (e: any) => onSelectChange(e),
  };

  const columnsLabels = [
    {
      title: "Guia",
      dataIndex: "guia",
      key: "guia",
      render: (f: any, x: Bill) => {
        return <a onClick={() => openDetailGuia(x.guia)}>{(x.status == 'Pendiente' || x.status == 'Impreso-R') ? x.guia : <div style={{ color: 'red' }}> {x.guia}</div>}</a>
      }
    },
    {
      title: "Fecha",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Transportadora",
      dataIndex: "company_TransportId",
      key: "company_TransportId",
      render: (f: any, x: Bill) => {
        let company: company_transport[] = companyTransportList.filter(
          (comp: company_transport) => x.company_TransportId === comp.id
        );
        return company.length > 0 ? company[0].name : "-";
      },
    },
    {
      title: "Venta",
      dataIndex: "bill_Products",
      key: "bill_Products",
      render: (f: any, x: Bill) => {
        let arrayProds = x.bill_Products.map((prod: BillProducts) => {
          let productFiltered = productList.filter(
            (p: product) => prod.productId === p.id
          );
          return productFiltered.length > 0
            ? `${prod.quantity} ${productFiltered[0].name}`
            : "";
        });
        return arrayProds.join();
      },
    },
    {
      title: "Ciudad",
      dataIndex: "city",
      key: "city",
      render: (f: any, x: Bill) => {
        let cityId: any = x.cityId ?? x.storeClient?.cityId
        let cityClient: city[] = citiesList.filter(
          (city: city) => city.id === cityId
        );
        return cityClient.length > 0
          ? `${cityClient[0].name} (${cityClient[0].provinceName})`
          : "";
      },
    },
    {
      title: "Recaudo",
      dataIndex: "collect_value",
      key: "collect_value",
      render: (f: any, x: Bill) => {
        return `$ ${x.collect_value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
    },
  ];

  const printLabels = (printMode: 'Rotulos' | "Guias") => {
    let selectedBills: Bill[] = selectedRowKeys.map(row => dataLabels.find((bill: Bill) => bill.id === row) as Bill);

    let labels: LabelModel[] = selectedBills.map(bill => {

      let total = bill.collect_value;
      let storeClient: any = bill.storeClient;
      let address = formatAddress(bill);
      let indications = formatIndications(bill);
      let city = bill.cityId !== undefined
          ? citiesList.find((city: city) => city.id === bill.cityId)
          : citiesList.find((city: city) => city.id === storeClient.cityId);

      let label: LabelModel = {
        date: moment(bill.created_at).format("YYYY-MM-DD"),
        store: bill.storeName ? bill.storeName : "",
        contactPhone: bill.storePhone ? bill.storePhone : "",
        addressee: storeClient.name,
        delivery_address: address,
        neighborhood: bill.neighborhood ?? storeClient?.neighborhood,
        city: city !== undefined ? `${city.name} (${city.provinceName})` : "-",
        celphone: storeClient.cellphone,
        indications: safeEndsWith(address, indications) ? "" : indications,
        products: formatBillProducts({billProducts: bill.bill_Products, products: productList }),
        collect_value: `$ ${total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        observations: bill.observaciones_Envio,
        guia: bill.guia,
        payment_home: bill.payment_home,
        billId: bill.id != undefined ? bill.id : 0,
        deliveryInWarehouse: bill.deliveryInWarehouse,
        clientIdentificacion: storeClient?.identification,
        storeIdentification: bill.storeIdentification ?? "",
        email: bill.email
      }

      return label;
    });

    setLabelsList(labels);
    setBillsArrayList(selectedBills)
    setPrintMode(printMode);
    setModalVisible(true);
  }

  const openDetailGuia = (guia: string) => {
    setSelectedGuia(guia);
    setModalDetailBillVisible(true);
  }

  const getPendingBillsGuias = () => {

    setLoading(true);
    axiosInstance.get<Bill[]>(`/Bills/GetBillsByStatus?Status=Impreso-R`).then(res => {
      let response = res.data.map((x: Bill) => {
        x.created_at = moment(x.created_at).format("YYYY-MM-DD HH:mm");
        x.key = x.id;
        return x
      })
      setLoading(false);
      setdataGuias(response.reverse());
    });
  }

  return {
    loading,
    dataLabels,
    dataGuias,
    columnsLabels,
    rowSelection,
    selectedCompanyTransport,
    companyTransportList,
    selectedRowKeys,
    labelsList,
    ModalVisible,
    billsArrayList,
    setModalVisible,
    setSelectedCompanyTransport,
    getPendingBillsByStatus,
    getProductList,
    getCompanyTransports,
    getCities,
    printMode,
    printLabels,
    ModalDetailBillVisible,
    setModalDetailBillVisible,
    selectedGuia,
    getPendingBillsGuias
  };
};

export default useWarehouse;
