import { BillProducts } from "./Products"
import { StoreClient } from "./StoreClient"
import { deliveryNews } from "./SuperAdmin/deliveryNews.model"

export type mySells = {
  id: number,
  guia: string,
  observaciones_Envio: string,
  created_at: string,
  collect_value: number,
  flete: number,
  cost: number,
  usD_Cost: number,
  status: string,
  company_Transport: string,
  forma_Pago: string,
  storeId: number,
  delivery_News: number,
  countryId: number
}

export type Bill = {
  id?: number,
  guia: string,
  delivery_value: number,
  status: 'Pendiente' | 'Anulado' | 'Despachado' | 'Devuelto' | 'Adelantado' | 'Pagado' | 'Entregado' | 'Novedad' | 'Impreso-G' | 'Impreso-R' | 'Solucionada',
  payment_home: boolean,
  collect_value: number,
  authorized: boolean,
  store_ClientId?: number,
  storeId: number,
  origin_city: number,
  created_by: number,
  anulation_comment_id?: number,
  company_TransportId: number,
  created_at?: string,
  updated_at?: string,
  bill_Products: BillProducts[],
  observaciones_Envio: string,
  envia?: Envia,
  key?: number,
  email: string,
  storeClient?: StoreClient,
  delivery_News?: deliveryNews[]
  deliveryInWarehouse?: boolean,
  countryId?: number,
  storeName?: string,
  storePhone?: string,
  storeIdentification?: string,
  address?: string
  cityId?: number,
  neighborhood?: string
  adress_indications?: string
}

export type Envia = {
  cod_FormaPago: number,
  mPesoVolumen_K: number,
  cod_Cuenta: number,
  nom_remitente: string,
  dir_remitente: string,
  tel_remitente: string,
  ced_remitente: string,
  texto_Guia: string
}

export interface BillData {
  id: number,
  guia: string,
  delivery_value: number,
  status: string,
  payment_home: boolean,
  collect_value: number,
  authorized: boolean,
  email: string,
  store_ClientId: number,
  storeId: number,
  origin_city: number,
  created_by: number,
  anulation_comment_id: number,
  company_TransportId: number,
  created_at: string,
  updated_at: string,
  bill_Products: BillProducts[],
  delivery_News: deliveryNews[],
  observaciones_Envio: string,
  envia: Envia,
  storeClient: StoreClient,
  crecelDeliveryId?: number,
  observaciones_Cancelacion?: string,
  courier?: string,
  deliveryInWarehouse?: boolean,
  deliveryComments?: string,
  cityId: number,
  address: string,
  neighborhood?: string
  adress_indications?: string
}

export type vw_Bills = {
  id: number,
  guia: string,
  delivery_value: number,
  status: string,
  warehouseId: number,
  created_at: string,
  client: string,
  phone: string,
  cellphone: string,
  email: string,
  identification: string,
  neighborhood: string,
  adress: string,
  adress_indications: string,
  city: string,
  province: string,
  products: string,
  payment_home: boolean,
  collect_value: number,
  store: string,
  company_Transport: string,
  urlGuia: string,
  dispatched: string,
  cost?: number,
  crecelUtility: number
}

export type palomaBillGet = {
  id: number,
  name: string,
  address: string,
  phone: string,
  document: string,
  email: string,
  company: string,
  consigned_Value: number,
  typeConsignedValue: 'Con Recaudo' | 'Sin Recaudo',
  created_at: string,
  updated_by: number,
  confirmed: boolean,
  observations: string,
  statusId: number,
  status: string,
  sellerId: number,
  seller: string,
  cityId: number,
  city: string,
  provinceId: number,
  province: string,
  company_TransportId: number,
  company_Transport: string,
  customerId: number,
  customer: string,
  collect_Value: number,
  number_account: string,
  guia: string,
  packing: string,
  packingQuantity: number,
  bill_Products: palomaProduct[],
  billNews: palomaBillNews[],
  totalPendingBillNews: number,
  totalSolvedBillNews: number,
  products: string,
  delivered_at: string,
  warehoseId: number,
  bill_News: string,
  createdBy: string,
  plate: string,
  zone: string
}

export type palomaProduct = {
  id: number,
  name: string,
  palomaName: string,
  code: string,
  quantity: number
  selling_price: number,
  customerId: number,
}

export type palomaBillNews = {
  id: number,
  billId: number,
  newsBillingType: string,
  description: string,
  solution: string,
  created_at?: string,
  update_at?: string,
  userID: number,
  changeStatus?: boolean
}

export type PreBill = {
  id: number,
  storeId: number,
  status: number,
  paymentHome: boolean,
  collecValue: number,
  createAt: Date,
  chanel: number,
  bill_Products: BillProducts[]
  description: string,
  ClientNumber: string,
  ClientName: string,
}
export enum ChanelPreBill {
  SmartChat = 1,
  Shopify = 2,
}