import {City} from "./city";

export const CITIES: Record<string, City> = {
    OTHER_CITY: {
        id: 7958,
        name: "Otras transportadoras / Interrapidisimo",
        code: 0,
        provinceCode: 0,
        provinceName: 'OTRO',
        isActive: true,
        message: null
    }
};